<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      :columns="columns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/hall/customer-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>
        <QueryLayout lable="人员类型">
          <Select
            v-model="queryParam.customerType"
            :options="customerType"
            :replace-fields="{
              lable: 'msg',
              value: 'code',
            }"
            placeholder="请选择人员类型"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="识别日期">
          <DatePicker v-model="queryParam.time" range></DatePicker>
        </QueryLayout>
      </template>

      <div slot="table-item-avatarUrl" slot-scope="{ row }">
        <v-avatar color="grey lighten-2" rounded size="36">
          <img
            :src="
              row.attachList && row.attachList[0]
                ? `${$imgUrl}/${row.attachList[0]}`
                : require('@/assets/screen/hallAssistant/default_avatar.png')
            "
          />
        </v-avatar>
      </div>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
      >
        <div
          style="color: #25c4ff"
          @click="$refs.customerIdentificationInfoDialog.openDialog({ row })"
        >
          查看
        </div>
      </div>
    </TableLayout>
    <CustomerIdentificationInfoDialog
      ref="customerIdentificationInfoDialog"
    ></CustomerIdentificationInfoDialog>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import Select from '@/views/screen/components/Select.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import CustomerIdentificationInfoDialog from './CustomerIdentificationInfoDialog.vue';
export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    Select,
    SelectTree,
    DatePicker,
    CustomerIdentificationInfoDialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '重点人员识别记录',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '人员类型',
          value: 'customerTypeText',
          sortable: false,
          align: 'center',
        },
        {
          text: '时间',
          value: 'warningTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '人员图像',
          value: 'avatarUrl',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      organizationTree: (state) => state.screenStore.organizationTree,
      customerType: (state) =>
        state.screenStore.commondict['CustomerTypeEnum']
          ? state.screenStore.commondict['CustomerTypeEnum']
          : [],
    }),
  },
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.dialogOptions = {
          dialog: {
            width: 1500,
            title: '重点人员识别记录',
          },
        };
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        ...this.dialogOptions.fixedParam,
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      return query;
    },
    openDialog(options = {}) {
      console.log(1.5);
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
