<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <v-row v-if="Object.keys(info).length" style="margin: 0">
      <v-col style="display: flex" :cols="12">
        <img
          :src="
           info.attachList && info.attachList[0]
              ? `${$imgUrl}${ info.attachList[0]}`
              : require('@/assets/screen/hallAssistant/default_avatar.png')
          "
          alt="头像"
          width="135"
          height="135"
          style="margin-right: 56px"
        />
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div style="display: flex">
            <div style="margin-right: 56px">{{ info.customerName }}</div>
            <div v-if="info.age">{{ info.age }}岁</div>
          </div>
          <div style="display: flex">
            <img
              src="@/assets/screen/hallAssistant/icon_phone.png"
              alt="手机"
              style="margin-right: 18px"
            />
            <div>{{ info.telNo }}</div>
          </div>
          <div v-if="info.customerCard" style="display: flex">
            <img
              src="@/assets/screen/hallAssistant/icon_cardid.png"
              alt="身份证"
              style="margin-right: 18px"
            />
            <div>{{ info.customerCard }}</div>
          </div>
        </div>
      </v-col>
      <v-col v-if="info.customerAnalysis" :cols="6">
        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #25e3ff;
            margin-bottom: 12px;
          "
        >
          人员分析
        </div>
        <div
          v-for="(e, i) in JSON.parse(info.customerAnalysis)"
          :key="i + 'customerAnalysis'"
          class="line"
          style="margin-bottom: 12px"
        >
          {{ e.value }}
        </div>
      </v-col>
      <v-col v-if="info.customerBusiness" :cols="6">
        <div
          style="
            font-size: 16px;
            font-weight: 400;
            color: #25e3ff;
            margin-bottom: 12px;
            margin-left: -12px;
          "
        >
          业务
        </div>
        <v-row>
          <v-col
            v-for="(e, i) in JSON.parse(info.customerBusiness)"
            :key="i + 'customerBusiness'"
            :cols="6"
            style="padding: 0; margin-bottom: 12px"
          >
            {{ e.name }}：<span
              :style="{ color: e.value == '已开通' ? '#06CD68' : '' }"
              >{{ e.value }}</span
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </Dialog>
</template>

<script>
import { assiginDeep } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
export default {
  //组件注册
  components: {
    Dialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      info: {},
      visible: false,
      dialogOptions: {
        dialog: {
          width: 791,
          title: '人员画像',
        },
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      if (this.dialogOptions.row && this.dialogOptions.row.uuid)
        this.getInfo(this.dialogOptions.row.uuid);
      this.visible = true;
      this.visible = true;
    },
    async getInfo(uuid) {
      try {
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/hall/customer-info/${uuid}`
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.info = data.data[0];
      } catch (error) {
        this.kalert({
          type: 'error',
          content: error + '',
        });
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  font-size: 16px;
  font-weight: 400;
  color: #d2e2e7;
}
.line {
  position: relative;
  margin-left: 16px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #25e3ff;
    border-radius: 100%;
    margin-right: 6px;
  }
}
</style>
