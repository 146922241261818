var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('TableLayout',{attrs:{"columns":_vm.columns,"query-format":_vm.queryFormat,"format-global-query":_vm.formatGlobalQuery,"url":("/" + _vm.$businessISG + "/monitor/hall/customer-page")},scopedSlots:_vm._u([{key:"query-form",fn:function(ref){
var queryParam = ref.queryParam;
return [_c('QueryLayout',{attrs:{"lable":"机构名称"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          },"placeholder":"请选择机构名称"},model:{value:(queryParam.organizationUUID),callback:function ($$v) {_vm.$set(queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1),_c('QueryLayout',{attrs:{"lable":"人员类型"}},[_c('Select',{attrs:{"options":_vm.customerType,"replace-fields":{
            lable: 'msg',
            value: 'code',
          },"placeholder":"请选择人员类型"},model:{value:(queryParam.customerType),callback:function ($$v) {_vm.$set(queryParam, "customerType", $$v)},expression:"queryParam.customerType"}})],1),_c('QueryLayout',{attrs:{"lable":"识别日期"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.time),callback:function ($$v) {_vm.$set(queryParam, "time", $$v)},expression:"queryParam.time"}})],1)]}},{key:"table-item-avatarUrl",fn:function(ref){
          var row = ref.row;
return _c('div',{},[_c('v-avatar',{attrs:{"color":"grey lighten-2","rounded":"","size":"36"}},[_c('img',{attrs:{"src":row.attachList && row.attachList[0]
              ? (_vm.$imgUrl + "/" + (row.attachList[0]))
              : require('@/assets/screen/hallAssistant/default_avatar.png')}})])],1)}},{key:"table-item-action",fn:function(ref){
              var row = ref.row;
return _c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"color":"#25c4ff"},on:{"click":function($event){return _vm.$refs.customerIdentificationInfoDialog.openDialog({ row: row })}}},[_vm._v(" 查看 ")])])}}],null,false,1967624363)}),_c('CustomerIdentificationInfoDialog',{ref:"customerIdentificationInfoDialog"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }